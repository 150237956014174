<template>
    <div>
        <v-card-text class="">
            <h1>Attach image to your service (optional)</h1>
        </v-card-text>
        <v-card class="rounded-xl" :elevation="localsave === true ? 1 : 0">
            <v-card-actions>
                <v-btn block text color="secondary" class="subsecondary mx-auto px-6"
                    @click="$refs.refprofilphoto.click()">
                    Pick image</v-btn>
            </v-card-actions>
            <v-card-text>
                <div><label for=""></label></div>
                <v-text-field v-model="imgdescription" hide-details label="Image decsription" type="text" outlined dense
                    placeholder="Brief description of your image">
                </v-text-field>
            </v-card-text>
            <v-card-text>
                <div outlined class="box-upload" @click="$refs.refprofilphoto.click()">
                    <v-img max-height="200" v-if="image" :alt="''" :src="image" cover></v-img>

                    <v-icon color="secondary"> {{ icons.mdiCamera }}</v-icon>
                    <input ref="refprofilphoto" type="file" accept=".svg,.jpeg,.png,.jpg,GIF" @change="onFileChange"
                        :hidden="true" />
                </div>
            </v-card-text>
            <v-card-actions v-if="image && localsave === true">
                <v-btn :loading="loadingImg" rounded color="secondary" class="mx-auto px-6"
                    @click="uploadAnSendImage(true)">
                    Send image</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>

import { Drequest } from '@/plugins/Drequest'
import { mdiCamera } from '@mdi/js'

export default {
    props: {
        localsave: {
            type: Boolean,
            default: true
        },
        saveimage: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadingImg: false,
            image: '',
            imgdescription: '',
            isAllreadyUploaded: false,
            isAllreadySaved: false,
            mfiles: null,
            responseFileData: []
        }
    },
    watch: {
        saveimage: function (newVal, oldVal) {
            if (newVal === true) {
                this.uploadAnSendImage()
            }
        },
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0]);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);

            this.mfiles = file;
            if (this.image !== null || this.image !== '') {
                this.$emit('imgselected', true)
            } else {
                this.$emit('imgselected', false)
            }
        },
        removeImage: function (e) {
            this.image = '';
            this.$emit('imgselected', false)
        },
        uploadAnSendImage() {
            var formdata = new FormData();
            this.loadingImg = true
            formdata.append("image", this.mfiles);
            Drequest.api("fileupload")
                .data(formdata)
                .post((response) => {
                    if (response.success) {
                        // service_id
                        const imageToDB = {
                            "serviceimage": {
                                "name": response.file.name,
                                "description": this.imgdescription,
                                "path": 'test',
                                "service.id": this.$store.getters.getService.id
                            }
                        }
                        Drequest.api("serviceimage.attach")
                            .data(imageToDB)
                            .raw((respons) => {
                                if (respons.success) {
                                    this.$emit('imagesaved', false)
                                    this.loadingImg = false
                                    // this.$router.push({ name: 'company.service.list' })
                                }
                                else {
                                    this.$fire({
                                        type: "error",
                                        text: "Error while saving the file",
                                        timer: 3000
                                    })
                                }
                            })
                            .catch((err) => {
                                this.$fire({
                                    type: "error",
                                    text: "Error while saving the file",
                                    timer: 3000
                                })
                                this.loadingImg = false;
                            })
                    }
                    else {
                        this.loadingImg = false
                        this.$fire({
                            type: "error",
                            text: "Error occurred while loading file",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    this.$fire({
                        type: "error",
                        text: "Error occurred while loading file",
                        timer: 3000
                    })
                    this.loadingImg = false;
                })
        }

    },

    setup(props) {
        const icons = { mdiCamera }
        return {
            icons
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    width: 30%;
    margin: auto;
    display: block;
    margin-bottom: 10px;
}

.box-upload {
    border: 1px dashed var(--v-secondary-base);
    width: 100%;
    box-sizing: border-box;
    background-color: var(--v-subsecondary-base);
    color: var(--v-secondary-base);
    border-radius: 0.5em;
    min-height: 4em !important;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
}
</style>